<template>
  <div class="container">
    <!-- Application Dashboard -->
    <div class="row justify-content-center" v-if="order">
      <div class="col-md-12">
        <order-overview :order="order" class="noprint"></order-overview>
        <order-items :order="order" class="noprint"/>
        <order-discounts :order="order" class="noprint"></order-discounts>
        <order-shipping :order="order" class="noprint"></order-shipping>
        <order-instalments :order="order" @refresh="fetchOrder" class="noprint"></order-instalments>
        <order-ledger-entries :order="order" class="noprint"></order-ledger-entries>
        <order-invoice :order="order" class="printit show-when-printing mt-4"></order-invoice>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col" v-if="permissionError">
        <access-error :itemType="'order'"/>
      </div>
      <div class="col" v-else>
        <loading class="my-4"></loading>
      </div>
    </div>
  </div>
</template>

<script>
import OrderItems from "./order-partials/OrderItems";
import OrderInstalments from "@/views/areas/orders/order-partials/OrderInstalments";
import OrderLedgerEntries from "@/views/areas/orders/order-partials/OrderLedgerEntries";
import OrderShipping from "@/views/areas/orders/order-partials/OrderShipping";
import OrderDiscounts from "@/views/areas/orders/order-partials/OrderDiscounts";
import OrderOverview from "@/views/areas/orders/order-partials/OrderOverview";
import OrderInvoice from "@/views/areas/orders/order-partials/OrderInvoice";

import AccessError from "../../components/alerts/AccessError"
import Loading from "@/views/components/loader/Loading";

export default {
  data() {
    return {
      order: null,
      permissionError: false
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  mounted() {
    this.fetchOrder();
  },
  methods: {
    fetchOrder() {
      this.$axios
          .get(
              process.env.VUE_APP_API_URL + "/orders/api/" + this.$route.params.id
          )
          .then(({ data }) => {
            this.order = data;
          })
          .catch(error => {
            if(error.response && error.response.status === 403)
            {
              this.permissionError = true;
            }
          });
    },
  },
  components: {
    OrderInvoice,
    OrderOverview,
    OrderDiscounts,
    OrderShipping,
    OrderLedgerEntries,
    OrderInstalments,
    OrderItems,
    AccessError,
    Loading
  },
};
</script>

<style>
th {
  border-top: none !important;
}
</style>
