<template>
  <div class="card border-0 mb-3" v-if="order">
    <div class="card-header">
      <h5 class="mb-0 mt-1">Ledger Entries</h5>
    </div>
    <div class="card-body pt-1">
      <table class="table">
        <thead>
        <tr>
          <th scope="col">Type</th>
          <th scope="col">Amount</th>
          <th scope="col">Payment Method</th>
          <th scope="col">Date</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="l in order.ledger_entries" :key="l.id">
          <td class="text-capitalize">
            {{ l.payment_type | formatText }}
          </td>
          <td :class="l.entry_type === 'debit' ? 'text-danger' : ''">
            <span v-if="l.entry_type === 'debit'">-</span>{{order.currency.symbol}}{{
              l.amount.toFixed(2)
            }}
          </td>
          <td class="text-capitalize">
            {{ l.payment_method | formatText }}
          </td>
          <td class="text-capitalize">{{ l.created_at | formatDate }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: ["order"],
  filters: {
    formatText(str) {
      return str.replace(/_/g, " ");
    },
    formatDate(date) {
      return moment(date).format("Do MMM YYYY");
    },
  }
}
</script>
<style>
th {
  border-top: none !important;
}
</style>
