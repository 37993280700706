<template>
  <div class="card border-0 mb-3" v-if="order.discounts.length > 0">
    <div class="card-header">
      <h5 class="mb-0 mt-1">Discount Codes</h5>
    </div>
    <div class="card-body pt-1">
      <table class="table">
        <thead>
        <tr>
          <th scope="col">Code</th>
          <th scope="col">Discount Rate</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="d in order.discounts" :key="d.id">
          <td>{{ d.code.code }}</td>
          <td>{{ d.code.discount_percent }}%</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: ["order"],
}
</script>
<style>
th {
  border-top: none !important;
}
</style>