<template>
  <div>
    <div
        class="card border-0 mb-3"
        v-if="order && order.type !== 'consultation' && order.type !== 'online_programme'"
    >
      <div class="card-header">
        <h5 class="mb-0 mt-1">Shipping Method</h5>
      </div>
      <div class="card-body pt-1">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Method</th>
            <th scope="col">Cost Price</th>
            <th scope="col">Retail Price</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="i in order.items" :key="i.id">
            <td v-if="isShippingMethod(i.product)">
              {{ i.id }}
            </td>
            <td v-if="isShippingMethod(i.product)">
              {{ i.name }}
            </td>
            <td v-if="isShippingMethod(i.product)">
              {{i.currency.symbol}}{{ i.cost.toFixed(2) }}
            </td>
            <td v-if="isShippingMethod(i.product)">
              {{i.currency.symbol}}{{ i.retail.toFixed(2) }}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="card border-0 mb-3" v-if="order.shipping_address">
      <div class="card-header">
        <h5 class="mb-0 mt-1">Shipping Address</h5>
      </div>
      <div class="card-body pt-1">
        <div class="row">
          <div class="col-md-6">
            <div class="bg-light p-3">
              <h5
                  v-clipboard:copy="order.shipping_address.label"
                  v-tooltip.bottom="'Click to copy'"
                  style="cursor: pointer"
              >
                {{ order.shipping_address.label }}
              </h5>
              <p
                  class="mb-1"
                  v-if="order.shipping_address.address_1"
                  v-clipboard:copy="order.shipping_address.address_1"
                  v-tooltip.bottom="'Click to copy'"
                  style="cursor: pointer"
              >
                {{ order.shipping_address.address_1 }}
              </p>
              <p
                  class="mb-1"
                  v-if="order.shipping_address.address_2"
                  v-clipboard:copy="order.shipping_address.address_2"
                  v-tooltip.bottom="'Click to copy'"
                  style="cursor: pointer"
              >
                {{ order.shipping_address.address_2 }}
              </p>
              <p
                  class="mb-1"
                  v-if="order.shipping_address.town"
                  v-clipboard:copy="order.shipping_address.town"
                  v-tooltip.bottom="'Click to copy'"
                  style="cursor: pointer"
              >
                {{ order.shipping_address.town }}
              </p>
              <p
                  class="mb-1"
                  v-if="order.shipping_address.city"
                  v-clipboard:copy="order.shipping_address.city"
                  v-tooltip.bottom="'Click to copy'"
                  style="cursor: pointer"
              >
                {{ order.shipping_address.city }}
              </p>
              <p
                  class="mb-1"
                  v-if="order.shipping_address.county"
                  v-clipboard:copy="order.shipping_address.county"
                  v-tooltip.bottom="'Click to copy'"
                  style="cursor: pointer"
              >
                {{ order.shipping_address.county }}
              </p>
              <p
                  class="mb-1"
                  v-if="order.shipping_address.post_code"
                  v-clipboard:copy="order.shipping_address.post_code"
                  v-tooltip.bottom="'Click to copy'"
                  style="cursor: pointer"
              >
                {{ order.shipping_address.post_code }}
              </p>
              <p class="mb-1" v-if="order.shipping_address.country">
                {{ order.shipping_address.country }}
              </p>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["order"],
  methods: {
    isShippingMethod(product) {
      return product ? product.model_type === "App\\Models\\ProductTypeShippingMethod" : false;
    }
  },
  filters: {
    formatText(str) {
      return str.replace(/_/g, " ");
    },
    formatDate(date) {
      return moment(date).format("Do MMM YYYY");
    },
  }
}
</script>
<style>
th {
  border-top: none !important;
}
</style>